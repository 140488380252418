import { applyAriaModalAttributes } from '../utils/aria';
import { lockFocus, unlockFocus } from '../utils/lock-focus';
import { lockScroll, unlockScroll } from '../utils/lock-scroll';
import { convertStringToNumber } from '../utils/type-utils/number';
import { pushBuyProductGtmEvent, pushOpenOverlayGtmEvent } from './subscription-tag-management';

const SELECT_PRODUCT_OVERLAY_SELECTOR = '.select-product-overlay';

export default function initSelectProductOverlay() {
  const subscriptionGroups = document.querySelectorAll('.subscription-group-block');
  subscriptionGroups.forEach(subscriptionGroup => {
    const selectProductOverlay = subscriptionGroup.querySelector(SELECT_PRODUCT_OVERLAY_SELECTOR);

    initOpenProductOverlayButton(subscriptionGroup);
    initBackButton(selectProductOverlay);
    initFormControls(selectProductOverlay);
    initSubmitButton(subscriptionGroup);
    addAccessibilityAttributes(selectProductOverlay);

    sortSelectionCards(selectProductOverlay);
    updateVisibleSelectionCards(selectProductOverlay);
  });
}

/**
 * @param { HTMLElement } selectProductOverlay
 */
function sortSelectionCards(selectProductOverlay) {
  const selectionCardList = selectProductOverlay.querySelector('.selection-card-list > ul');
  const selectionCards = Array.from(selectionCardList.children);

  selectionCards.sort((a, b) => {
    const priceAHasFirstSortPriority = a.dataset.firstSortPriority === 'True';
    const priceBHasFirstSortPriority = b.dataset.firstSortPriority === 'True';
    if(priceAHasFirstSortPriority !== priceBHasFirstSortPriority) {
      return priceAHasFirstSortPriority ? -1 : 1;
    }

    const priceA = convertStringToNumber(a.dataset.pricePerMonth);
    const priceB = convertStringToNumber(b.dataset.pricePerMonth);
    return priceA - priceB;
  });

  selectionCards.forEach(card => {
    selectionCardList.appendChild(card);
  });
}

/**
 * @param { HTMLElement } selectProductOverlay
 */
function updateVisibleSelectionCards(selectProductOverlay) {
  const selectionCards = selectProductOverlay.querySelectorAll('.selection-card-list > ul > li');
  const labelsForProductsToShow = getLabelsForProductsToShow(selectProductOverlay);

  selectionCards.forEach(card => {
    const shouldHide = shouldHideSelectionCard(card.dataset.displayLabel, labelsForProductsToShow);
    card.classList.toggle('hidden', shouldHide);
    card.querySelector('input[type="radio"]').toggleAttribute('disabled', shouldHide);
    selectProductOverlay.setAttribute('aria-hidden', false);
  });

  checkFirstVisibleSelectionCard(selectProductOverlay);
}

/**
 * @param { string } displayLabel Label that determines if the card should be shown
 * @param { string[] } labelsForProductsToShow Array of labels for products that should be shown
 * @returns { boolean } True if the card should be hidden, false if it should be shown
 */
function shouldHideSelectionCard(displayLabel, labelsForProductsToShow) {
  const cardContainsAnyLabel = labelsForProductsToShow.some(label => displayLabel.includes(label));
  const shouldHide =
    (labelsForProductsToShow.length === 0 && displayLabel !== '')
    || (labelsForProductsToShow.length > 0 && !cardContainsAnyLabel);

  return shouldHide;
}

/**
 * @param { HTMLElement } selectProductOverlay
 */
function checkFirstVisibleSelectionCard(selectProductOverlay) {
  const visibleSelectionCards = selectProductOverlay.querySelectorAll('.selection-card-list > ul > li:not(.hidden)');
  const firstVisibleSelectionCard = visibleSelectionCards[0];
  if(firstVisibleSelectionCard) {
    const radioButton = firstVisibleSelectionCard.querySelector('input[type="radio"]');
    radioButton.checked = true;
  }
}

/**
 * @param { HTMLElement } subscriptionGroup
 */
function initOpenProductOverlayButton(subscriptionGroup) {
  const chooseSubscriptionButton = subscriptionGroup.querySelector('.choose-subscription-button');
  const selectProductOverlay = subscriptionGroup.querySelector(SELECT_PRODUCT_OVERLAY_SELECTOR);
  const subscriptionGroupName = subscriptionGroup.querySelector('h2').textContent;

  chooseSubscriptionButton.addEventListener('click', () => {
    selectProductOverlay.classList.remove('hidden');
    lockScroll(document.body);
    lockFocus(selectProductOverlay);

    pushOpenOverlayGtmEvent(subscriptionGroupName);
  });
}

/**
 * @param { HTMLElement } selectProductOverlay
 */
function initBackButton(selectProductOverlay) {
  const backButton = selectProductOverlay.querySelector('.back-button');
  backButton.addEventListener('click', e => {
    e.preventDefault();
    selectProductOverlay.classList.add('hidden');
    unlockScroll(document.body);
    unlockFocus();
  });
}

/**
 * @param { HTMLElement } subscriptionGroup
 */
function initFormControls(subscriptionGroup) {
  const toggles = subscriptionGroup.querySelectorAll(`${SELECT_PRODUCT_OVERLAY_SELECTOR} .toggle-container input`);
  toggles.forEach(toggle => {
    toggle.addEventListener('change', () => {
      updateVisibleSelectionCards(subscriptionGroup);
    });
  });

  const selects = subscriptionGroup.querySelectorAll(`${SELECT_PRODUCT_OVERLAY_SELECTOR} select`);
  selects.forEach(select => {
    select.addEventListener('change', () => {
      updateVisibleSelectionCards(subscriptionGroup);
    });
  });
}

/**
 * @param { HTMLElement } subscriptionGroup
 */
function initSubmitButton(subscriptionGroup) {
  const selectProductOverlay = subscriptionGroup.querySelector(SELECT_PRODUCT_OVERLAY_SELECTOR);
  const buyCardButton = selectProductOverlay.querySelector('button[type="submit"]');
  if(!buyCardButton) {
    return;
  }

  buyCardButton.addEventListener('click', () => {
    handleSubmitButtonGTMEvent(subscriptionGroup);
  });
}

/**
 * @param { HTMLElement } subscriptionGroup
 */
function handleSubmitButtonGTMEvent(subscriptionGroup) {
  const selectProductOverlay = subscriptionGroup.querySelector(SELECT_PRODUCT_OVERLAY_SELECTOR);

  const selectedProductInputSelector = '.selection-card-list input[type="radio"]:checked';
  const selectedPurchaseMethod = selectProductOverlay.querySelector(`${selectedProductInputSelector} + label .secondary-text`).textContent;
  const selectedProductId = selectProductOverlay.querySelector(selectedProductInputSelector).value;

  const toggles = selectProductOverlay.querySelectorAll('.toggle-container input');
  const isDayCard = Array.from(toggles).some(toggle => toggle.id.includes('dagkort') && toggle.checked);

  const subscriptionGroupName = subscriptionGroup.querySelector('h2').textContent;

  pushBuyProductGtmEvent(subscriptionGroupName, selectedPurchaseMethod, selectedProductId, isDayCard);
}

/**
 * @param { HTMLElement } selectProductOverlay
 */
function addAccessibilityAttributes(selectProductOverlay) {
  const dialogLabel = selectProductOverlay.querySelector(':scope > fieldset > legend');
  applyAriaModalAttributes(selectProductOverlay, dialogLabel.id);

  const toggles = selectProductOverlay.querySelectorAll('.toggle-container input');
  const selects = selectProductOverlay.querySelectorAll('select');

  const selectionCardList = selectProductOverlay.querySelector('.selection-card-list');
  const selectionCardListId = selectionCardList.getAttribute('id');

  toggles.forEach(toggle => {
    toggle.setAttribute('aria-controls', selectionCardListId);
  });

  selects.forEach(select => {
    select.setAttribute('aria-controls', selectionCardListId);
  });

  selectionCardList.setAttribute('aria-live', 'polite');
}

/**
 * @param { HTMLElement } selectProductOverlay
 * @returns { Array<string> } Array of labels for products to show.
 */
function getLabelsForProductsToShow(selectProductOverlay) {
  const toggles = selectProductOverlay.querySelectorAll('.toggle-container input');
  const selects = selectProductOverlay.querySelectorAll('select');
  const labels = [];

  toggles.forEach(toggle => {
    if(toggle.checked) {
      labels.push(toggle.value);
    }
  });

  selects.forEach(select => {
    const selectedOption = select.options[select.selectedIndex];
    if(selectedOption.value !== '') {
      labels.push(selectedOption.value);
    }
  });

  return labels;
}
