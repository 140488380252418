let focusStack = [];

/**
 * @param { HTMLElement } element Element to lock focus inside.
 */
export function lockFocus(element) {
  if(focusStack.length > 0) {
    const { handler } = focusStack[focusStack.length - 1];
    document.removeEventListener('focus', handler, true);
  }

  const handler = createFocusHandler(element);
  focusStack.push({ element, handler });

  if(element.getAttribute('tabindex') === null) {
    element.setAttribute('tabindex', '-1');
  }

  document.addEventListener('focus', handler, true);
}

/**
 * Unlock focus from the current element and re-lock it to the previous one if any.
 */
export function unlockFocus() {
  if(focusStack.length === 0) {
    return;
  }

  const { handler: unlockedHandler } = focusStack.pop();
  document.removeEventListener('focus', unlockedHandler, true);

  if(focusStack.length > 0) {
    const { element: topElement, handler: topHandler } = focusStack[focusStack.length - 1];
    document.addEventListener('focus', topHandler, true);
    topElement.focus();
  }
}

/**
 * @param { HTMLElement } element Element to lock focus inside.
 * @returns { (event: Event) => void } Focus handler.
 */
function createFocusHandler(element) {
  return function focusHandler(event) {
    if(!element.contains(event.target)) {
      element.focus();
    }
  };
}
