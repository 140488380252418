import { handleError, setLoading, getSearchResultsContainer, getSearchResultsList, addSearchResultsGradient } from './search-utils';
import { handleInfiniteScroll } from '../utils/scroll';
import { createElementWithText } from '../utils/dom';

/**
 * @typedef { object } SearchResultItem
 * @property { string } title The title of the search result
 * @property { string } uriPath The URL to the search result
 * @property { string } text A snippet of text from the search result
 * @property { string } contentTypeTagName The type of page the search result links to
 */

/**
 * @typedef { object } SearchResponse
 * @property { SearchResultItem[] } searchResultItems List of search results
 * @property { number } totalNumberOfItems Total number of search results
 */

let searchTerm = '';
let totalNumberOfItems = 0;
let baseUrl = window.location.origin;
let controller = new AbortController();
let signal = controller.signal;

/**
 * @param { string } searchString Search term
 */
export function fetchSearchResults(searchString) {
  searchTerm = encodeURIComponent(searchString);
  fetch(`${baseUrl}/api/search?searchTerm=${searchTerm}`, { signal })
    .then(response => response.json())
    .then(data => {
      handleSearchResponse(data);
      setLoading(false);
    })
    .catch(handleError);
}

export function abortFetch() {
  controller.abort();
  controller = new AbortController();
  signal = controller.signal;
}

/**
 * @param { SearchResponse } data Search response
 */
function handleSearchResponse(data) {
  totalNumberOfItems = data.totalNumberOfItems;
  const searchResultsContainer = getSearchResultsContainer();
  searchResultsContainer.innerHTML = '';

  const numberOfItemsElement = createElementWithText('span', `${totalNumberOfItems} träffar`);
  searchResultsContainer.appendChild(numberOfItemsElement);

  const searchResultsList = createSearchResultsList(data.searchResultItems);
  searchResultsContainer.appendChild(searchResultsList);
}

export function fetchMoreSearchResults() {
  const skip = calculateNumberOfItemsToSkip();
  if(skip === 0) {
    return;
  }

  setLoading(true);
  fetch(`${baseUrl}/api/search?searchTerm=${searchTerm}&skip=${skip}`, { signal })
    .then(response => response.json())
    .then(data => {
      const searchResultsList = getSearchResultsList();
      data.searchResultItems.forEach(searchResult => {
        const searchItem = createSearchItem(searchResult);
        searchResultsList.appendChild(searchItem);
      });
      setLoading(false);
    })
    .catch(handleError);
}

/**
 * @returns { number } Number of items to skip
 */
function calculateNumberOfItemsToSkip() {
  const searchResultsList = getSearchResultsList();
  const numberOfItems = searchResultsList?.children.length;
  if(!numberOfItems || numberOfItems >= totalNumberOfItems) {
    return 0;
  }
  return numberOfItems;
}

/**
 * @param { SearchResultItem[] } searchResults List of search results
 * @returns { HTMLUListElement } A list of search results
 */
function createSearchResultsList(searchResults) {
  const searchResultsList = document.createElement('ul');
  searchResultsList.classList.add('search-results-list');
  searchResults.forEach(searchResult => {
    const searchItem = createSearchItem(searchResult);
    searchResultsList.appendChild(searchItem);
  });

  addSearchResultsGradient(searchResultsList);
  searchResultsList.addEventListener('scroll', () =>
    handleInfiniteScroll(searchResultsList, fetchMoreSearchResults)
  );
  return searchResultsList;
}

/**
 * @param { SearchResultItem } searchResult Search result
 * @returns { HTMLLIElement | null } A list item containing a search result or null if missing title or uriPath
 */
function createSearchItem(searchResult) {
  const { title, contentTypeTagName, text, uriPath } = searchResult;
  const searchItem = document.createElement('li');
  const searchItemLink = document.createElement('a');
  const hGroupElement = document.createElement('hgroup');

  if(title === '' || uriPath === '') {
    return null;
  }

  if(contentTypeTagName !== '') {
    const pageTypeElement = createElementWithText('span', contentTypeTagName);
    hGroupElement.appendChild(pageTypeElement);
  }

  const titleElement = createElementWithText('h3', title);
  hGroupElement.appendChild(titleElement);

  if(text !== '') {
    const textElement = createElementWithText('p', text);
    hGroupElement.appendChild(textElement);
  }

  searchItemLink.appendChild(hGroupElement);
  searchItemLink.href = uriPath;
  searchItem.appendChild(searchItemLink);

  return searchItem;
}
