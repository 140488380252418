/**
 * @param { HTMLElement } modal Modal element.
 * @param { string } labelledbyId Id of element to use as aria-labelledby.
 */
export function applyAriaModalAttributes(modal, labelledbyId) {
  modal.setAttribute('aria-modal', 'true');
  modal.setAttribute('role', 'dialog');
  modal.setAttribute('aria-labelledby', labelledbyId);

  modal.setAttribute('aria-hidden', 'true');
  updateAriaHiddenOnClassHidden(modal);
}

function updateAriaHiddenOnClassHidden(element) {
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if(mutation.type === 'attributes' && mutation.attributeName === 'class') {
        const isHidden = element.classList.contains('hidden');
        element.setAttribute('aria-hidden', isHidden);
      }
    });
  });

  observer.observe(element, { attributes: true });
}
