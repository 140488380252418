export default function initMainMenuSelected() {
  const menuLinks = document.querySelectorAll('.main-menu li a');
  const currentPath = window.location.pathname;

  menuLinks.forEach((link) => {
    if(isCurrentPath(link.pathname, currentPath)) {
      link.classList.add('selected');
    }
  });
}

/**
 * @param { string } linkPath Link path.
 * @param { string } currentPath Current path.
 * @returns { boolean } True if the link is the current path.
 */
function isCurrentPath(linkPath, currentPath) {
  const cleanedLinkPath = cleanPath(linkPath);
  const cleanedCurrentPath = cleanPath(currentPath);

  const currentPathParts = getPathParts(cleanedCurrentPath);
  return currentPathParts.some((_, i) => {
    const path = joinPathParts(currentPathParts.slice(0, i + 1)); // Incrementally add path parts.
    return cleanedLinkPath === path;
  });
}

/**
 * @param { string } path Path to clean.
 * @returns { string } Cleaned path.
 */
function cleanPath(path) {
  return joinPathParts(getPathParts(path));
}

/**
 * @param { string } path Pathname to split.
 * @returns { string[] } Array of path parts.
 */
function getPathParts(path) {
  return path.split('/').filter(part => part !== '');
}

/**
 * @param { string[] } pathParts Path parts to join.
 * @returns { string } Joined path.
 */
function joinPathParts(pathParts) {
  return pathParts.join('/');
}
