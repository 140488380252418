/**
 * @param { HTMLElement } container Container to add interaction layer to
 */
export function initClickToInteractOverlay(container) {
  const interactOverlayClass = 'interact-overlay';
  const interactOverlayExists = container.querySelector(`.${interactOverlayClass}`);
  if(interactOverlayExists) {
    return;
  }

  const overlay = document.createElement('div');
  overlay.classList.add(interactOverlayClass);
  overlay.classList.add('hidden');

  const interactionInstructions = document.createElement('p');
  interactionInstructions.innerText = 'Klicka på kartan för att interagera';
  overlay.appendChild(interactionInstructions);

  container.appendChild(overlay);
  initShowListeners(
    container,
    overlay,
    ['mouseenter', 'touchstart'],
    ['mouseleave', 'touchend']
  );

  container.addEventListener('click', () => {
    overlay.remove();
  });
}

/**
 * @param { HTMLElement } container Container to add interaction layer to
 * @param { HTMLElement } overlay Overlay to hide or show
 * @param { Array<string> } showEvents Events to show interaction layer on
 * @param { Array<string> } hideEvents Events to hide interaction layer on
 */
function initShowListeners(container, overlay, showEvents, hideEvents) {
  showEvents.forEach(event => {
    container.addEventListener(event, () => {
      overlay.classList.remove('hidden');
    }, { passive: true });
  });

  hideEvents.forEach(event => {
    container.addEventListener(event, () => {
      overlay.classList.add('hidden');
    }, { passive: true });
  });
}
