import { lockFocus, unlockFocus } from '../utils/lock-focus';
import { lockScroll, unlockScroll } from '../utils/lock-scroll';

const MAIN_MENU_CLASS = 'main-menu';
const TOGGLE_MENU_CLASS = 'toggle-menu-items';

export default function initToggleMenu() {
  const toggleButton = document.querySelector(`.${MAIN_MENU_CLASS} .toggle-menu-button`);
  toggleButton.addEventListener('click', toggleMenu);
}

function toggleMenu() {
  const mainMenu = document.querySelector(`.${MAIN_MENU_CLASS}`);
  const shouldExpand = mainMenu.getAttribute('aria-expanded') !== 'true';

  shouldExpand ? openMenu() : closeMenu();
}

function openMenu() {
  const mainMenu = document.querySelector(`.${MAIN_MENU_CLASS}`);
  showToggleMenu();
  mainMenu.setAttribute('aria-expanded', true);
  lockScroll(document.body);
  lockFocus(mainMenu);
}

function closeMenu() {
  const mainMenu = document.querySelector(`.${MAIN_MENU_CLASS}`);
  hideToggleMenu();
  mainMenu.setAttribute('aria-expanded', false);
  unlockScroll(document.body);
  unlockFocus();
}

function showToggleMenu() {
  const hiddenItems = getHiddenMenuItemsCopy();
  if(hiddenItems.length === 0) {
    return;
  }

  const toggleMenu = document.createElement('ul');
  toggleMenu.classList.add(TOGGLE_MENU_CLASS);

  hiddenItems.forEach((item) => {
    toggleMenu.appendChild(item);
  });

  const mainMenu = document.querySelector(`.${MAIN_MENU_CLASS}`);
  mainMenu.appendChild(toggleMenu);
}

function hideToggleMenu() {
  const toggleMenu = document.querySelector(`.${MAIN_MENU_CLASS} .${TOGGLE_MENU_CLASS}`);
  toggleMenu.remove();
}

/**
 * @returns {Array<HTMLLIElement>} Array of copies of hidden menu items.
 */
function getHiddenMenuItemsCopy() {
  const menuItems = document.querySelectorAll(`.${MAIN_MENU_CLASS} ul li`);
  const hiddenItems = [];

  menuItems.forEach((item) => {
    const isHidden = window.getComputedStyle(item).display === 'none';

    if(isHidden) {
      const copy = item.cloneNode(true);
      copy.classList.remove('hidden-mobile', 'hidden-tablet');
      hiddenItems.push(copy);
    }
  });

  return hiddenItems;
}
