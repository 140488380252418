/**
 * @typedef { 'horizontal' | 'vertical' } Direction
 * @typedef { 'start' | 'end' } Position
 */

/**
 * @typedef { object } ScrollOptions
 * @property { Direction } [direction] Direction to scroll.
 * @property { Position } [position] Position to add scroll button for.
 */

/**
 * @typedef { object } ScrollToOptions
 * @property { number } [scrollOffset] Left margin to add to scroll position.
 * @property { ScrollBehavior } [scrollBehaviour] Scroll behavior.
 */

/**
 * @param { HTMLElement } scrollContainer Scrollable container.
 * @param { HTMLElement } gradientContainer Container that should hold gradient. Should be some parent scrollContainer.
 * @param { ScrollOptions } options Options for scroll gradient.
 */
export function addScrollGradient(scrollContainer, gradientContainer, options = {}) {
  options = setScrollOptionDefaults(options);

  const gradient = document.createElement('div');
  gradient.classList.add(`${options.direction}-scroll-gradient`);
  gradient.classList.add(options.position);

  scrollContainer.addEventListener('scroll', () => toggleScrollElementVisibility(scrollContainer, gradient, options));
  const resizeObserver = new ResizeObserver(() => toggleScrollElementVisibility(scrollContainer, gradient, options));
  resizeObserver.observe(scrollContainer);

  gradientContainer.appendChild(gradient);
}

/**
 * @param { HTMLElement } scrollContainer Scrollable container.
 * @param { HTMLElement } buttonContainer Container that should hold gradient. Should be some parent scrollContainer.
 * @param { ScrollOptions } options Options for scroll button.
 */
export function addHorizontalScrollButton(scrollContainer, buttonContainer, options = {}) {
  options = setScrollOptionDefaults(options);

  const scrollButton = document.createElement('button');
  scrollButton.classList.add(`horizontal-scroll-button`);
  scrollButton.classList.add(options.position);
  scrollButton.classList.add('hidden');
  scrollButton.setAttribute('aria-hidden', 'true');

  scrollContainer.addEventListener('scroll', () => toggleScrollElementVisibility(scrollContainer, scrollButton, options));
  const resizeObserver = new ResizeObserver(() => toggleScrollElementVisibility(scrollContainer, scrollButton, options));
  resizeObserver.observe(scrollContainer);

  scrollButton.addEventListener('click', () => {
    handleScrollButtonClick(scrollContainer, options);
  });

  buttonContainer.appendChild(scrollButton);
}

/**
 * @param { HTMLElement } scrollContainer Scrollable container.
 * @param { ScrollOptions } options Options for scroll button.
 */
function handleScrollButtonClick(scrollContainer, options) {
  const { direction, position } = setScrollOptionDefaults(options);

  const nextElement = findNextElement(scrollContainer, options);

  if(!nextElement) {
    const currentScrollPosition = getScrollPosition(scrollContainer, direction);
    const visibleDimension = getVisibleDimension(scrollContainer, direction);

    const newScrollPosition = position === 'end'
      ? currentScrollPosition + visibleDimension
      : currentScrollPosition - visibleDimension;
    scrollToPosition(scrollContainer, newScrollPosition, { direction });
    return;
  }

  scrollToElement(scrollContainer, nextElement, { direction, scrollOffset: 80});
}

/**
 * Finds next element in scrollable container based on current scroll position and visible dimension.
 * @param { HTMLElement } scrollContainer Scrollable container.
 * @param { ScrollOptions } options Options for scroll.
 * @returns { Element | undefined } Next element in scrollable container.
 */
function findNextElement(scrollContainer, options) {
  const { direction, position } = setScrollOptionDefaults(options);

  const currentScrollPosition = getScrollPosition(scrollContainer, direction);
  const visibleDimension = getVisibleDimension(scrollContainer, direction);
  const elements = Array.from(scrollContainer.children);

  if(position === 'end') {
    return elements.find(element => {
      const elementStart = direction === 'horizontal' ? element.offsetLeft : element.offsetTop;
      const elementEnd = direction === 'horizontal' ? elementStart + element.offsetWidth : elementStart + element.offsetHeight;
      return elementEnd > (currentScrollPosition + visibleDimension);
    });
  } else {
    return elements.reverse().find(element => {
      const elementStart = direction === 'horizontal' ? element.offsetLeft : element.offsetTop;
      return elementStart < currentScrollPosition;
    });
  }
}

/**
 * @param { HTMLElement } scrollContainer Scrollable container.
 * @param { number } position Position to scroll to.
 * @param { ScrollOptions & ScrollToOptions } options Options for scrolling.
 */
export function scrollToPosition(scrollContainer, position, options) {
  const { direction, scrollBehaviour } = setScrollToOptionDefaults(options);

  scrollContainer.scrollTo({
    [direction === 'horizontal' ? 'left' : 'top']: position,
    behavior: scrollBehaviour
  });
}

/**
 * @param { HTMLElement } scrollContainer Container to scroll.
 * @param { HTMLElement } element Element to scroll to.
 * @param { ScrollOptions & ScrollToOptions } options Options for scrolling.
 */
export function scrollToElement(scrollContainer, element, options) {
  const { direction, scrollOffset, scrollBehaviour } = setScrollToOptionDefaults(options);

  const position = direction === 'horizontal' ? element.offsetLeft : element.offsetTop;

  let finalPosition = position - scrollOffset;

  scrollToPosition(scrollContainer, finalPosition, direction, scrollBehaviour);
  scrollContainer.dispatchEvent(new Event('scroll'));
}

/**
 * Calls the callback function when the user has scrolled to the bottom of the scroll container.
 * To be used on scroll event.
 * @param { HTMLElement } scrollContainer Scrollable container.
 * @param { Function } reachedBottomCallback Callback function to be called when the bottom has been reached.
 */
export function handleInfiniteScroll(scrollContainer, reachedBottomCallback) {
  if(hasReachedBottom(scrollContainer)) {
    reachedBottomCallback();
  }
}

/**
 * @param { HTMLElement } scrollContainer Scrollable container.
 * @param { number } bottomOffset Offset from bottom to consider as bottom.
 * @returns { boolean } True if the scroll container has reached the bottom.
 */
function hasReachedBottom(scrollContainer, bottomOffset = 20) {
  const { scrollTop, scrollHeight, clientHeight } = scrollContainer;
  const scrollableHeight = scrollHeight - clientHeight;
  const bottomThreshold = scrollableHeight - bottomOffset;
  return scrollTop > 0 && scrollTop >= bottomThreshold;
}

/**
 * @param { HTMLElement } scrollContainer Scrollable container.
 * @param { HTMLElement } element Element to toggle visibility for.
 * @param { ScrollOptions } options Options for scroll
 */
function toggleScrollElementVisibility(scrollContainer, element, options) {
  const { direction, position } = setScrollOptionDefaults(options);

  if(isScrolledTo(position, direction, scrollContainer)) {
    element.classList.add('hidden');
  } else {
    element.classList.remove('hidden');
  }
}

/**
 * @param { Position } position Position to check
 * @param { Direction } direction Direction to check
 * @param { HTMLElement } scrollContainer Scrollable container
 * @returns { boolean } True if the scroll container has reached the specified position.
 */
export function isScrolledTo(position, direction, scrollContainer) {
  const tolerance = 1; // Tolerance for scroll position needed for some browsers

  if(direction === 'horizontal') {
    const scrollableWidth = scrollContainer.scrollWidth - scrollContainer.clientWidth;
    if(position === 'end') {
      return scrollContainer.scrollLeft >= scrollableWidth - tolerance;
    } else {
      return scrollContainer.scrollLeft <= tolerance;
    }
  } else {
    const scrollableHeight = scrollContainer.scrollHeight - scrollContainer.clientHeight;
    if(position === 'end') {
      return scrollContainer.scrollTop >= scrollableHeight - tolerance;
    } else {
      return scrollContainer.scrollTop <= tolerance;
    }
  }
}

/**
 * @param { HTMLElement } scrollContainer Scrollable container.
 * @param { Direction } direction Direction to check.
 * @returns { number } The visible dimension of the scroll container.
 */
function getVisibleDimension(scrollContainer, direction) {
  return direction === 'horizontal' ? scrollContainer.clientWidth : scrollContainer.clientHeight;
}

/**
 * @param { HTMLElement } scrollContainer Scrollable container.
 * @param { Direction } direction Direction to check.
 * @returns { number } The scroll position of the scroll container.
 */
function getScrollPosition(scrollContainer, direction) {
  return direction === 'horizontal' ? scrollContainer.scrollLeft : scrollContainer.scrollTop;
}

/**
 * @param { ScrollOptions } options Scroll options
 * @returns { ScrollOptions } Options with default values set.
 */
function setScrollOptionDefaults(options) {
  const defaults = {
    direction: 'horizontal',
    position: 'end',
  };
  return { ...defaults, ...options };
}

/**
 * @param { ScrollOptions & ScrollToOptions } options Scroll to options
 * @returns { ScrollOptions & ScrollToOptions } Options with default values set.
 */
function setScrollToOptionDefaults(options) {
  const defaults = {
    direction: 'horizontal',
    position: 'end',
    scrollOffset: 0,
    scrollBehaviour: 'smooth'
  };
  return { ...defaults, ...options };
}
