/**
 * @param { HTMLElement } element Element to lock the scroll on.
 */
export function lockScroll(element) {
  element.style.overflow = 'hidden';
}

/**
 * @param { HTMLElement } element Element to unlock the scroll on.
 */
export function unlockScroll(element) {
  element.style.overflow = 'auto';
}
