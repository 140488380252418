import '../styles/index.scss';
import initMainMenu from './main-menu/index';
import initMapBlock from './map-block/index';
import initSiteSearch from './site-search/index';
import initTabs from './tabs.mjs';
import initEventFiltering from './event-filtering';
import initSubMenu from './sub-menu';
import initTableWrapper from './table-wrapper';
import initSubscriptions from './subscriptions';
import initCarouselListning from './carousel-listing';

initMainMenu();
initMapBlock();
initSiteSearch();
initTabs();
initSubscriptions();
initEventFiltering();
initSubMenu();
initTableWrapper();
initCarouselListning();
