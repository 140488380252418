/**
 * @param { string } tagName Tag name of element to create
 * @param { string } text Text content
 * @returns { HTMLElement } Element with text content
 */
export function createElementWithText(tagName, text) {
  const element = document.createElement(tagName);
  element.textContent = text;
  return element;
}
