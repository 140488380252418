export class Debouncer {
  constructor(delay) {
    this.delay = delay;
    this.timer = null;
  }

  debounce(callback) {
    clearTimeout(this.timer);
    this.timer = setTimeout(callback, this.delay);
  }

  cancel() {
    clearTimeout(this.timer);
    this.timer = null;
  }
}
