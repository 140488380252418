import { initClickToInteractOverlay } from './click-to-interact-overlay';

let mapbox; // Init mapbox when first map block enters screen

export default function initMapBlock() {
  const mapboxContainers = document.querySelectorAll('.map-block');
  if(mapboxContainers.length === 0) {
    return;
  }

  const observer = new IntersectionObserver(handleMapEnterScreen);
  mapboxContainers.forEach(container => observer.observe(container));
}

/**
 * @param { Array<IntersectionObserverEntry> } entries
 */
async function handleMapEnterScreen(entries) {
  entries.forEach(async(entry) => {
    if(entry.isIntersecting && mapbox === undefined) {
      const mapboxModule = await import('mapbox-gl');
      await import('mapbox-gl/dist/mapbox-gl.css');
      mapbox = mapboxModule.default;

      initMapBlocks();
    }

    if(!entry.isIntersecting) {
      initClickToInteractOverlay(entry.target);
    }
  });
}

function initMapBlocks() {
  const mapBlockContainers = document.querySelectorAll('.map-block');

  mapBlockContainers.forEach((container, i) => {
    const mapbox = container.querySelector('.mapbox');
    const markers = container.querySelectorAll('.marker');

    const mapboxId = `mapbox-${i}`;
    mapbox.id = mapboxId;
    const map = createMap(mapboxId);
    markers.forEach(marker => createMarker(marker).addTo(map));

    const boundingBox = calculateBoundingBox(markers);
    map.fitBounds(boundingBox, { padding: 60 });

    initClickToInteractOverlay(container);
  });
}

/**
 * @param { string } containerId Id of container to create map in
 * @returns { mapbox.Map } Mapbox map instance
 */
function createMap(containerId) {
  mapbox.accessToken = 'pk.eyJ1IjoieGxlbnR1bWVhIiwiYSI6ImNsb2lzdDh5aTFuemIybG8yM3EzZHhrNzYifQ.kymwPvhlL1Bpne7nAQ19qw';
  return new mapbox.Map({
    container: containerId,
    style: 'mapbox://styles/xlentumea/cloir4ho7004s01qoahkp2v3l',
    center: [20.252805456342443, 63.82961200593257], // Set Umeå as initial position
    zoom: 12
  });
}

/**
 *
 * @param { HTMLElement } marker HTML element to create marker for
 * @returns { mapbox.Marker } Marker for map
 */
function createMarker(marker) {
  const popup = createPopup(marker);

  const lngLat = getLngLatFromMarker(marker);
  const mapboxMarker = new mapbox.Marker(marker)
    .setLngLat(lngLat)
    .setPopup(popup);
  return mapboxMarker;
}

/**
 * @param { HTMLElement } marker Marker to create popup for
 * @returns { mapbox.Popup } Popup for marker
 */
function createPopup(marker) {
  const popupNode = marker.querySelector('.popup');
  if(popupNode === undefined) {
    return new mapbox.Popup({ offset: 25 });
  }

  const popupHTML = popupNode.innerHTML;
  popupNode.remove();
  return new mapbox.Popup({ offset: 25 }).setHTML(popupHTML);
}

/**
 * @param { HTMLElement } marker Marker to get coordinates from
 * @returns { Array<string> } Array with longitude and latitude
 */
function getLngLatFromMarker(marker) {
  const latitude = marker.dataset.latitude;
  const longitude = marker.dataset.longitude;
  return [longitude, latitude];
}

/**
 * @param { NodeListOf<HTMLElement> } markers Markers to calculate bounding box for
 * @returns { mapbox.LngLatBounds } Bounding box for markers
 */
function calculateBoundingBox(markers) {
  const lngLats = [];
  markers.forEach(marker => {
    const lngLat = getLngLatFromMarker(marker);
    lngLats.push(lngLat);
  });

  const longitudes = lngLats.map(lngLat => lngLat[0]);
  const latitudes = lngLats.map(lngLat => lngLat[1]);

  let minLng = Math.min(...longitudes);
  let maxLng = Math.max(...longitudes);
  let minLat = Math.min(...latitudes);
  let maxLat = Math.max(...latitudes);

  const minBoundingBoxSize = 0.005;
  [minLng, maxLng] = adjustAxisToMinBoundingBoxSize([minLng, maxLng], minBoundingBoxSize);
  [minLat, maxLat] = adjustAxisToMinBoundingBoxSize([minLat, maxLat], minBoundingBoxSize);

  const southWest = new mapbox.LngLat(minLng, minLat);
  const northEast = new mapbox.LngLat(maxLng, maxLat);
  return new mapbox.LngLatBounds(southWest, northEast);
}

/**
 * @param { Array<number> } minMaxPoints Minimum and maximum point of bounding box in either longitude or latitude. Should be along same axis.
 * @param { number } minBoundingBoxSize Minimum size of bounding box
 * @returns { Array<number> } Minimum and maximum point adjusted to minimum bounding box size if below value.
 */
function adjustAxisToMinBoundingBoxSize(minMaxPoints, minBoundingBoxSize) {
  if(minMaxPoints.length !== 2) {
    throw new Error('minMaxPoints should have two elements');
  }

  const minPoint = minMaxPoints[0];
  const maxPoint = minMaxPoints[1];
  const difference = maxPoint - minPoint;

  if(difference < minBoundingBoxSize) {
    const adjustment = (minBoundingBoxSize - difference) / 2;
    return [
      minPoint - adjustment,
      maxPoint + adjustment
    ];
  }

  return [minPoint, maxPoint];
}
