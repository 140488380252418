import { Debouncer } from '../utils/debouncer';
import { lockFocus, unlockFocus } from '../utils/lock-focus';
import { lockScroll, unlockScroll } from '../utils/lock-scroll';
import { setLoading, getSearchResultsContainer } from './search-utils';
import { fetchSearchResults, abortFetch } from './search-fetch';
import { applyAriaModalAttributes } from '../utils/aria';

const SEARCH_OVERLAY_SELECTOR = '.site-search-overlay';

const debouncer = new Debouncer(500);
/** @type { HTMLElement[] } */
let popularSearchSuggestions = null;

export default function initSiteSearch() {
  initOpenSearchButton();
  initCloseSearchButton();
  initSearchInput();
  initAriaAttributes();
  initPopularChoices();
}

function initOpenSearchButton() {
  const mainMenu = document.querySelector('.main-menu');
  // Because the search button can be inside toggle menu which is not created until the
  // main menu is expanded we need to use event delegation instead of querySelector here
  mainMenu.addEventListener('click', function(event) {
    if(event.target.closest('button.search')) {
      toggleSearchOverlay(true);
    }
  });
}

function initCloseSearchButton() {
  const closeSearchButton = document.querySelector(`${SEARCH_OVERLAY_SELECTOR} .close-search`);
  closeSearchButton.addEventListener('click', () => {
    toggleSearchOverlay(false);
  });
}

/**
 * @param { boolean } show Whether or not to show the search overlay
 */
function toggleSearchOverlay(show) {
  const searchOverlay = document.querySelector(SEARCH_OVERLAY_SELECTOR);
  const body = document.body;
  if(show) {
    searchOverlay.classList.remove('hidden');
    lockScroll(body);
    lockFocus(searchOverlay);
  } else {
    searchOverlay.classList.add('hidden');
    unlockScroll(body);
    unlockFocus(searchOverlay);
  }
}

function initSearchInput() {
  const searchResultsContainer = getSearchResultsContainer();
  const searchInput = document.querySelector(`${SEARCH_OVERLAY_SELECTOR} input[type="search"]`);
  searchInput.value = '';

  searchInput.addEventListener('input', () => {
    const searchTerm = searchInput.value;
    searchResultsContainer.innerHTML = '';
    abortFetch();

    if(searchTerm.length >= 3) {
      setLoading(true);
      debouncer.debounce(() => fetchSearchResults(searchTerm));
    } else {
      debouncer.cancel();
      setLoading(false);
      appendPopularChoices();
    }
  });

  searchInput.addEventListener('keydown', event => {
    // On mobile this blurs the input when the user presses the search button on the keyboard
    if(event.key === 'Enter') {
      searchInput.blur();
    }
  });
}

function initAriaAttributes() {
  const searchOverlay = document.querySelector(SEARCH_OVERLAY_SELECTOR);
  const searchHeading = searchOverlay.querySelector('h2');
  applyAriaModalAttributes(searchOverlay, searchHeading.id);
}

function initPopularChoices() {
  popularSearchSuggestions = Array.from(getSearchResultsContainer().children).map(child => child.cloneNode(true));
}

function appendPopularChoices() {
  const searchResultsContainer = getSearchResultsContainer();
  for(let i = 0; i < popularSearchSuggestions.length; i++) {
    searchResultsContainer.appendChild(popularSearchSuggestions[i]);
  }
}
