const mainMenu = document.querySelector('.main-menu');
const TRANSITION_DURATION_MS = 300;
let prevScrollPos = window.scrollY;
let currentScrollPos = window.scrollY;
let isAtTop = currentScrollPos <= 0;

mainMenu.style.transition = `transform ${TRANSITION_DURATION_MS / 1000}s`;

export default function initMainMenuVisibility() {
  handleScrollAtTop();

  window.addEventListener('scroll', handleScroll);
  mainMenu.addEventListener('focusin', () => updateMenuVisibility(true));
}

function handleScroll() {
  currentScrollPos = window.scrollY;
  isAtTop = currentScrollPos <= 0;

  const isScrollingUp = prevScrollPos >= currentScrollPos;
  updateMenuVisibility(isAtTop || isScrollingUp);

  handleMenuTransparencyOnScroll();

  prevScrollPos = currentScrollPos;
}

function handleMenuTransparencyOnScroll() {
  const isScrollingDown = currentScrollPos > 0 && currentScrollPos > prevScrollPos;
  const isFirstScrollFromTop = isAtTop && isScrollingDown;

  if(isFirstScrollFromTop) {
    updateMenuTransparency(false, TRANSITION_DURATION_MS);
  }

  handleScrollAtTop(TRANSITION_DURATION_MS);
}

/**
 * @param { number } transparencyDelay Delay in milliseconds for menu transparency change.
 */
function handleScrollAtTop(transparencyDelay = 0){
  const headerDisplaysBackground = document.querySelector('.header.show-background') !== null;
  const shouldBeTransparent = isAtTop && headerDisplaysBackground;
  updateMenuTransparency(shouldBeTransparent, transparencyDelay);
}

/**
 * @param {boolean} visible If true, the menu will be visible. If false, the menu will be hidden.
 */
function updateMenuVisibility(visible) {
  mainMenu.style.transform = visible ? 'translateY(0)' : 'translateY(-100%)';
}

/**
 * @param {boolean} transparent Whether the menu should be transparent.
 * @param {number} delay Delay in milliseconds for menu transparency change.
 */
function updateMenuTransparency(transparent, delay = 0) {
  if(delay !== 0) {
    setTimeout(() => {
      mainMenu.classList.toggle('transparent', transparent);
    }, delay);

    return;
  }

  mainMenu.classList.toggle('transparent', transparent);
}
