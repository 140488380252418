import { pushToDataLayer } from '../utils/tag-management';
import { cleanString } from '../utils/type-utils/string';

/**
 * @param { string } subscriptionGroupName Name of the subscription group.
 */
export function pushOpenOverlayGtmEvent(subscriptionGroupName) {
  const event = {
    event: 'click-bli-medlem',
    målgrupp: getCurrentCategory(),
    medlemskap: cleanString(subscriptionGroupName)
  };
  pushToDataLayer(event);
}

/**
 * @param { string } subscriptionGroupName Name of the subscription group.
 * @param { string } purchaseMethod Name of the selected product.
 * @param { string } productId Id of the selected product.
 * @param { boolean } isDayCard Whether the selected product is a day card.
 */
export function pushBuyProductGtmEvent(subscriptionGroupName, purchaseMethod, productId, isDayCard) {
  const event = {
    event: 'click-köp-kort',
    målgrupp: getCurrentCategory(),
    medlemskap: cleanString(subscriptionGroupName),
    produktid: cleanString(productId),
    betalmetod: cleanString(purchaseMethod),
    dagkort: isDayCard ? 'ja' : 'nej'
  };
  pushToDataLayer(event);
}

function getCurrentCategory() {
  const currentCategoryButton = document.querySelector('.subscriptions [role="tab"][aria-selected="true"]');
  if(!currentCategoryButton) {
    return '';
  }

  return cleanString(currentCategoryButton.textContent);
}
