import { addScrollGradient } from '../utils/scroll';
import { createElementWithText } from '../utils/dom';

/**
 * @param { HTMLElement } scrollContainer Scrollable container for search results
 */
export function addSearchResultsGradient(scrollContainer) {
  const gradientContainer = getSearchResultsContainer();
  addScrollGradient(scrollContainer, gradientContainer, { direction: 'vertical' });
}

/**
 * @param { boolean } loading Whether or not to show loading indicator
 */
export function setLoading(loading) {
  const searchResultsContainer = getSearchResultsContainer();
  if(loading) {
    searchResultsContainer.classList.add('loading');
  } else {
    searchResultsContainer.classList.remove('loading');
  }
}

/**
 * @returns { HTMLElement | null } Search results container or undefined if not found
 */
export function getSearchResultsContainer() {
  return document.querySelector('.site-search-overlay .search-results-container');
}

/**
 * @returns { HTMLElement | null } Search results container or undefined if not found
 */
export function getSearchResultsList() {
  return getSearchResultsContainer().querySelector('.search-results-list');
}

/**
 * @param { Error } error Error
 */
export function handleError(error) {
  if(error.name === 'AbortError') {
    return;
  }

  console.error(error);

  const searchResultsContainer = getSearchResultsContainer();
  searchResultsContainer.innerHTML = '';
  const errorMessage = createElementWithText('span', 'Det gick inte att hämta sökresultat');
  searchResultsContainer.appendChild(errorMessage);

  setLoading(false);
}
